//
// DISCLAIMER
//
// Copyright 2019-2023 ArangoDB GmbH, Cologne, Germany
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Grid, Icon, Loader, Menu, Message, Header, Segment } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  OrganizationEntityDetails as ApiOrganizationEntityDetails,
  IDOptions as ApiIDOptions,
  Organization as ApiOrganization,
  OrganizationInfo as ApiOrganizationInfo,
  SetOrganizationCustomInvoiceThresholdRequest as ApiSetOrganizationCustomInvoiceThresholdRequest,
  SetOrganizationTechnicalAccountManagerRequest as ApiSetOrganizationTechnicalAccountManagerRequest,
  ListOptions as ApiListOptions,
} from "../../api/lib";
import Auth from "../../auth/Auth";
import {
  ContentSegment,
  ErrorMessage,
  EditBox,
  Field,
  FieldContent as FC,
  FieldLabelWide as FL,
  LoaderBox,
  MenuActionBack,
  SecondaryMenu,
  Section,
  SectionContent,
  Locked,
} from "../../ui/lib";
import { hasSupportPermission, Permission } from "../../util/PermissionCache";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import BillingDetails from "../billing/BillingDetails";
import InvoiceList from "../billing/InvoiceList";
import DeploymentList from "../deployment/DeploymentList";
import EventList from "../event/EventList";
import OrganizationMemberList from "../organization-members/OrganizationMemberList";
import ProjectList from "../project/ProjectList";
import QuotaList from "../quota/QuotaList";
import UsageItemList from "./UsageItemList";
import AuditLogList from "../auditlog/AuditLogList";
import TierField from "./TierField";
import CostCenterField from "./CostCenterField";
import BillingField from "./BillingField";
import FlexibleDeploymentsField from "./FlexibleDeploymentsField";
import SalesforceURLsField from "./SalesforceURLsField";
import CustomImageFiltersField from "./CustomImageFiltersField";
import CustomImageAllowedField from "./CustomImageAllowedField";
import SCIMAllowedField from "./SCIMAllowedField";
import OrganizationCreditsView from "./OrganizationCreditsView";
import OrganizationPrepaidDeploymentsView from "./OrganizationPrepaidDeploymentsView";
import JiraFields from "./JiraFields";
import DataclusterLabelFilterField from "./DataclusterLabelFilterField";
import OrganizationInternalContactsField from "./OrganizationInternalContactsField";
import IAMProvidersFields from "./IAMProvidersFields";
import KubernetesAccessAllowedField from "./KubernetesAccessAllowedField";
import FeatureFlags from "../feature/FeatureFlags";
import BusinessAssociateAgreementSignedField from "./BusinessAssociateAgreementSignedField";
import RequiresPrepaidDeploymentsField from "./RequiresPrepaidDeploymentsField";
import EmailMessageList from "../email/EmailMessageList";
import RevenueReporting from "../revenue/RevenueReporting";
import { FreeToTryView } from "./FreeToTryView";
import { slice } from "lodash";
import { ICommentsStateForIntegration, ICommentsViewIntegrationArgs } from "../comments/CommentTypes";
import {
  CommentList as ApiCommentList,
  CreateCommentRequest as ApiCreateCommentRequest,
  ListCommentsRequest as ApiListCommentsRequest,
} from "../../api/comment/v1/icomment";
import TabLayoutWithRouter from "../../components/Tab/TabWithRouter";
import { ITabConfig } from "../../components/Tab/TabTypes";
import PrivateDataClusterFieldToggle, { IPrivateDataClusterFieldToggleArgs } from "./PrivateDataClusterFieldToggle";
import CommentsSectionInline from "../comments/CommentSectionInline";
import { AsyncResult } from "../../util/Types";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import OrganizationDeploymentProfilesView from "./OrganizationDeploymentProfilesView";
import AutomaticInvoiceVerifcationFieldToggle from "./AutomaticInvoiceVerifcationFieldToggle";
import AllowDelinquentPayerToggle from "./AllowDelinquentPayerToggle";
import AllowCustomDeploymentSettingsToggle from "./AllowCustomDeploymentSettingsToggle";
import OrganizationCreditBundlesView from "./OrganizationCreditsBundlesView";
import UsesCreditBundles from "./UsesCreditBundles";
import { OrganizationNotificationsList } from "./OrganizationNotificationsList";

interface IOrganizationDetailsViewArgs extends IWithRefreshProps, RouteComponentProps, ICommentsViewIntegrationArgs, IPrivateDataClusterFieldToggleArgs {
  organization: ApiOrganization;
  organizationInfo: ApiOrganizationInfo;
  entityInfo: ApiOrganizationEntityDetails;
  onRefreshOrganization: () => void;
  onError: (message: string) => void;
  onUpdateCustomInvoiceThreshold: (val: string) => Promise<AsyncResult<void>>;
  onUpdateTechnicalAccountManager: (val: string) => Promise<AsyncResult<void>>;
  canEditCustomInvoiceThreshold: boolean;
  canSetTechnicalAccountManager: boolean;
  canSetOrganizationAutomaticInvoiceVerification: boolean;
  canSetOrganizationAllowDelinquentPayer: boolean;
  canSetDisableCustomCPUSettings: boolean;
  canSetDisableCustomDiskSettings: boolean;
}

const OrganizationDetailsView = ({ ...args }: IOrganizationDetailsViewArgs) => {
  const entity = args.entityInfo.entity || {};
  const entity_explicit = !!args.entityInfo.is_explicit;
  const is_deleted = !!args.organization.is_deleted;
  const business_associate_agreement_signed = !!args.organizationInfo.business_associate_agreement_signed;
  const isQualified = !!args.organizationInfo.is_qualified_lead;

  const customInvoiceThreshold = args.organizationInfo.custom_invoice_threshold || 0;
  const technicalManager = args.organizationInfo.technical_account_manager || "";

  return (
    <Segment>
      <Grid columns="3" doubling>
        <Grid.Column>
          <Header sub>General</Header>
          <Field>
            <FL>Name</FL>
            <FC>
              {args.organization.name || "-"}
              {!!args.organization.locked && <Locked />}
            </FC>
          </Field>
          <Field>
            <FL>Description</FL>
            <FC>{args.organization.description || "-"}</FC>
          </Field>
          <Field>
            <FL>ID</FL>
            <FC>
              <div>{args.organization.id || "-"}</div>
            </FC>
          </Field>
          <Field>
            <FL>Created</FL>
            <FC>{args.organization.created_at ? <DateTimePopupWithUTCAndLocalTime dateTime={args.organization.created_at} label="Created at" /> : "-"}</FC>
          </Field>
          {is_deleted && (
            <Field>
              <FL>Deleted</FL>
              <FC>{args.organization.deleted_at ? <DateTimePopupWithUTCAndLocalTime dateTime={args.organization.deleted_at} label="Deleted at" /> : "-"}</FC>
            </Field>
          )}

          <Header sub>Sales</Header>
          <TierField {...args} />
          <CostCenterField {...args} />
          <Field>
            <FL>Qualification</FL>
            <FC>{isQualified ? "ArangoGraph qualified!" : "Not qualified"}</FC>
          </Field>
          <Field>
            <FL>Entity</FL>
            <FC>
              {entity.name || "-"}
              &nbsp;
              {entity_explicit && <Icon name="exclamation" />}
            </FC>
          </Field>
          <BillingField {...args} />
          <Field>
            <FL>Custom invoice threshold (in default currency)</FL>
            <FC>
              <EditBox
                {...args}
                initialValue={`${customInvoiceThreshold}`}
                update={args.onUpdateCustomInvoiceThreshold}
                placeholder="Custom invoice threshold eg: 200"
                editable={args.canEditCustomInvoiceThreshold}
                label="Custom invoice threshold (in default currency)"
              />
            </FC>
          </Field>
          <Field>
            <FL>Technical Account Manager</FL>
            <FC>
              <EditBox
                {...args}
                initialValue={`${technicalManager}`}
                update={args.onUpdateTechnicalAccountManager}
                placeholder="Technical account manager name or email address"
                editable={args.canSetTechnicalAccountManager}
                label={`Technical Account manager for ${args.organization.name}`}
              />
            </FC>
          </Field>
          <BusinessAssociateAgreementSignedField {...args} />
          <RequiresPrepaidDeploymentsField {...args} />
          <AutomaticInvoiceVerifcationFieldToggle
            canSetOrganizationAutomaticInvoiceVerification={args.canSetOrganizationAutomaticInvoiceVerification}
            organization={args.organization}
            organizationInfo={args.organizationInfo}
            onError={args.onError}
          />
          <Field>
            <FL>Is delinquent payer</FL>
            <FC>{(args.organizationInfo || {}).is_delinquent_payer ? "Yes" : "No"}</FC>
          </Field>
          <AllowDelinquentPayerToggle
            canSetOrganizationAllowDelinquentPayer={args.canSetOrganizationAllowDelinquentPayer}
            organization={args.organization}
            organizationInfo={args.organizationInfo}
            onError={args.onError}
          />
          <OrganizationInternalContactsField {...args} />
        </Grid.Column>
        <Grid.Column>
          <Header sub>Configuration</Header>
          <FlexibleDeploymentsField {...args} />
          <CustomImageAllowedField {...args} />
          <CustomImageFiltersField {...args} />
          <DataclusterLabelFilterField {...args} />
          <IAMProvidersFields {...args} />
          <SCIMAllowedField {...args} />
          <UsesCreditBundles {...args} />
          <KubernetesAccessAllowedField {...args} />
          <PrivateDataClusterFieldToggle
            canSetHasPrivateDataCluster={args.canSetHasPrivateDataCluster}
            organization={args.organization}
            organizationInfo={args.organizationInfo}
            onError={args.onError}
          />
          <AllowCustomDeploymentSettingsToggle {...args} />
          <Header sub>Integrations</Header>
          <JiraFields {...args} />
          <SalesforceURLsField {...args} />
          <Field>
            <FL>Bluesnap ID</FL>
            <FC>{args.organizationInfo.bluesnap_id || "-"}</FC>
          </Field>
          <FreeToTryView {...args} />
          <Header sub>Authentication Providers</Header>
          <Field>
            <FL>Google</FL>
            <FC>{(args.organization.authentication_providers || {}).enable_google ? "Enabled" : "Disabled"}</FC>
          </Field>
          <Field>
            <FL>Github</FL>
            <FC>{(args.organization.authentication_providers || {}).enable_github ? "Enabled" : "Disabled"}</FC>
          </Field>
          <Field>
            <FL>Microsoft</FL>
            <FC>{(args.organization.authentication_providers || {}).enable_microsoft ? "Enabled" : "Disabled"}</FC>
          </Field>
          <Field>
            <FL>Username / Password</FL>
            <FC>{(args.organization.authentication_providers || {}).enable_username_password ? "Enabled" : "Disabled"}</FC>
          </Field>
          <Field>
            <FL>Single Sign On</FL>
            <FC>{(args.organization.authentication_providers || {}).enable_sso ? "Enabled" : "Disabled"}</FC>
          </Field>
        </Grid.Column>
        <Grid.Column>
          <CommentsSectionInline
            onCommentsPageChange={args.onCommentsPageChange}
            canCreate={args.canCreateComment}
            canViewComments={args.canViewComments}
            canCreateComment={args.canCreateComment}
            createComment={args.createComment}
            commentList={args.commentList}
            latestComments={args.latestComments}
            commentsLoading={args.commentsLoading}
            commentCreationInProcess={args.commentCreationInProcess}
            lastestCommentsTriggered={args.lastestCommentsTriggered}
          />
        </Grid.Column>
      </Grid>
      {business_associate_agreement_signed && (
        <Message color="orange">
          <Message.Header>This organization has signed a business associate agreement (BAA) related to HIPAA data!</Message.Header>
          <p>Deployments of this organization must be handled by HIPAA authorized personel only!</p>
        </Message>
      )}
    </Segment>
  );
};

// Interface decribing the properties of the organization details component
interface IOrganizationDetailsProps extends IWithRefreshProps, RouteComponentProps {
  auth: Auth;
  organizationId?: string;
  onProjectSelected: (projectId: string) => void;
  onUserSelected: (userId: string) => void;
  onDeploymentSelected: (id: string) => void;
  onAuditLogSelected: (id: string) => void;
  onAuditLogArchiveSelected: (id: string) => void;
  onOrganizationSelected: (id: string) => void;
  onInvoiceSelected: (id: string) => void;
  onDataClusterSelected: (id: string) => void;
}

// Interface decribing the state of the organization details component
interface IOrganizationDetailsState extends ICommentsStateForIntegration {
  organization?: ApiOrganization;
  organizationInfo?: ApiOrganizationInfo;
  entityInfo?: ApiOrganizationEntityDetails;
  errorMessage?: string;
}

class OrganizationDetails extends Component<IOrganizationDetailsProps, IOrganizationDetailsState> {
  state: IOrganizationDetailsState = {
    organization: undefined,
    organizationInfo: undefined,
    entityInfo: undefined,
    errorMessage: undefined,
    commentList: {},
    commentsLoading: false,
    commentsPageOptions: {
      page: 0,
      pageSize: 10,
    },
    commentsFetchError: undefined,
    commentCreationInProcess: false,
    latestComments: {},
    lastestCommentsTriggered: false,
  };

  hasPermission = (permission: Permission) => hasSupportPermission(permission, this.props.hasPermissionByUrl);

  reloadOrganizationInfo = async () => {
    const organizationId = this.props.organizationId;
    if (organizationId) {
      // Users may not have permission for all requests.
      // Therefore we check permission for individual calls.
      // Permission for base GetOrganization call is required in any case.
      const idOptions: ApiIDOptions = { id: organizationId };

      const organizationInfo = await apiClients.idashboardClient.GetOrganizationInfo(idOptions);
      this.setState({
        organization: organizationInfo.organization,
        organizationInfo: organizationInfo,
      });

      if (this.hasPermission("internal-dashboard.organization.get-entity")) {
        const entityInfo = await apiClients.idashboardClient.GetOrganizationEntity(idOptions);
        this.setState({ entityInfo: entityInfo });
      }
      this.props.refreshWithTimer && this.props.refreshWithTimer(this.reloadLatestComments, 5000);
    }
  };

  getComments = async (options: ApiListOptions) => {
    const canListComments = hasSupportPermission("internal-dashboard.comment.list", this.props.hasPermissionByUrl);
    if (!canListComments) return;

    if (this.state.commentCreationInProcess) {
      return;
    }

    const { organization = {} } = this.state;
    const { url = "" } = organization;

    if (!url) {
      return;
    }

    const req: ApiListCommentsRequest = {
      resource_url: url,
      options,
    };

    try {
      const commentList = (await apiClients.idashboardClient.ListComments(req)) || {};
      this.setState({ commentsLoading: false, commentsFetchError: undefined });
      return commentList;
    } catch (err) {
      this.setState({ commentsFetchError: err, commentsLoading: false });
    }
  };

  reloadLatestComments = async () => {
    const latestComments = await this.getComments({ page: 0, page_size: 10 });
    if (!!latestComments) {
      if (!this.state.lastestCommentsTriggered) {
        this.setState({ commentList: latestComments });
      }
      this.setState({ latestComments, lastestCommentsTriggered: true });
    }
  };

  reloadComments = async () => {
    const { page, pageSize } = this.state.commentsPageOptions;

    const commentList = await this.getComments({ page, page_size: pageSize });
    if (!!commentList) {
      this.setState({ commentList });
    }
  };

  onCommentsPageChange = (page: number, pageSize: number) => {
    this.setState({ commentsPageOptions: { page, pageSize } }, this.reloadComments);
  };

  createComment = async (comment: string): Promise<AsyncResult<void>> => {
    let error = "";
    this.setState({ commentCreationInProcess: true });
    const { organization = {}, commentsPageOptions } = this.state;
    const { url = "" } = organization;
    const { pageSize, page } = commentsPageOptions;
    const req: ApiCreateCommentRequest = {
      resource_url: url,
      comment,
    };
    try {
      const comment = await apiClients.idashboardClient.CreateComment(req);
      const { items = [] } = this.state.commentList;
      const newCommentList: ApiCommentList = {
        items: [comment, ...slice(items, 0, pageSize - 1)],
      };
      this.setState(
        {
          commentsFetchError: undefined,
          commentCreationInProcess: false,
          commentList: page > 0 ? this.state.commentList : newCommentList,
          latestComments: {
            items: [comment, ...slice(this.state.latestComments.items, 0, pageSize - 1)],
          },
        },
        this.reloadComments
      );
    } catch (err) {
      this.setState({ commentsFetchError: err, commentCreationInProcess: false }, this.reloadComments);
      error = err.message;
    }
    return { error };
  };

  refreshOrganizationInfo = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadOrganizationInfo);
  };

  onError = (message: string) => {
    this.setState({ errorMessage: message });
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  componentDidMount() {
    this.refreshOrganizationInfo();
  }
  onUpdateCustomInvoiceThreshold = async (val: string): Promise<AsyncResult<void>> => {
    const req: ApiSetOrganizationCustomInvoiceThresholdRequest = {
      organization_id: this.props.organizationId || "",
      custom_invoice_threshold: Number.parseFloat(val),
    };
    let error = "";
    try {
      await apiClients.idashboardClient.SetOrganizationCustomInvoiceThreshold(req);
    } catch (err) {
      error = err;
    }
    this.refreshOrganizationInfo();
    return { error };
  };

  onUpdateTechnicalAccountManager = async (val: string): Promise<AsyncResult<void>> => {
    const req: ApiSetOrganizationTechnicalAccountManagerRequest = {
      organization_id: this.props.organizationId || "",
      technical_account_manager: val,
    };
    let error = "";
    try {
      await apiClients.idashboardClient.SetOrganizationTechnicalAccountManager(req);
    } catch (err) {
      error = err;
    }
    this.refreshOrganizationInfo();
    return { error };
  };

  getTabConfig = (): ITabConfig[] => {
    const organization = this.state.organization || {};
    const panes: ITabConfig[] = [
      {
        menuItem: { id: "membersTab", route: "members", children: "Members" },
        content: () => <OrganizationMemberList {...this.props} organization={organization} onMemberSelected={this.props.onUserSelected} showHeader={false} />,
      },
      {
        menuItem: { id: "projectsTab", route: "projects", children: "Projects" },
        content: () => <ProjectList {...this.props} organization={organization} showHeader={false} />,
      },
      {
        menuItem: { id: "deploymentsTab", route: "deployments", children: "Deployments" },
        content: () => <DeploymentList {...this.props} organization_id={organization.id} />,
      },
      {
        menuItem: { id: "quotaTab", route: "quota", children: "Quota" },
        content: () => <QuotaList {...this.props} organization={organization} showHeader={false} />,
      },
      {
        menuItem: { id: "usageTab", route: "usage", children: "Usage" },
        content: () => <UsageItemList {...this.props} organization_id={organization.id} />,
      },
      {
        menuItem: { id: "billingTab", route: "billing", children: "Billing" },
        content: () => <BillingDetails {...this.props} organization_id={organization.id} />,
      },
      {
        menuItem: { id: "invoicesTab", route: "invoices", children: "Invoices" },
        content: () => <InvoiceList {...this.props} organization_id={organization.id} showHeader={false} />,
      },
      {
        menuItem: { id: "creditsTab", route: "organizationVouchers", children: "Vouchers" },
        content: () => <OrganizationCreditsView {...this.props} organization={organization} />,
      },
      {
        menuItem: { id: "creditBundlesTab", route: "organizationCreditBundles", children: "Credit Bundles" },
        content: () => <OrganizationCreditBundlesView {...this.props} organization={organization} />,
      },
      {
        menuItem: { id: "revenueTab", route: "revenue", children: "Revenue" },
        content: () => <RevenueReporting {...this.props} organizationID={organization.id} />,
      },
      {
        menuItem: { id: "eventsTab", route: "events", children: "Events" },
        content: () => <EventList {...this.props} subject_id={organization.id} organization_id={organization.id} showHeader={false} />,
      },
      {
        menuItem: { id: "emailsTab", route: "emails", children: "Emails" },
        content: () => <EmailMessageList {...this.props} organization_id={organization.id} />,
      },
      {
        menuItem: { id: "auditLogsTab", route: "auditLogs", children: "AuditLogs" },
        content: () => <AuditLogList {...this.props} organization_id={organization.id} />,
      },
      {
        menuItem: { id: "deploymentProfilesTab", route: "deploymentProfiles", children: "Deployment Profiles" },
        content: () => <OrganizationDeploymentProfilesView {...this.props} organization={organization} />,
      },
      {
        menuItem: { id: "betaFeatureFlagsTab", route: "betaFeatureFlags", children: "Beta feature flags" },
        content: () => <FeatureFlags {...this.props} organization_id={organization.id} />,
      },
      {
        menuItem: { id: "prepaidDeploymentsTab", route: "prepaidDeployments", children: "Prepaid Deployments" },
        content: () => <OrganizationPrepaidDeploymentsView {...this.props} organization={organization} />,
      },
      {
        menuItem: { id: "notificationsTab", route: "notifications", children: "Notifications" },
        content: () => <OrganizationNotificationsList organization={organization} refreshOrganizationInfo={this.refreshOrganizationInfo} />,
      },
    ];

    return panes;
  };

  render() {
    const organization = this.state.organization;
    const organizationInfo = this.state.organizationInfo;
    const canEditCustomInvoiceThreshold = this.hasPermission("internal-dashboard.organization.set-custom-invoice-threshold");
    const canViewComments = this.hasPermission("internal-dashboard.comment.list");
    const canCreateComment = this.hasPermission("internal-dashboard.comment.create");
    const canSetInternalContacts = this.hasPermission("internal-dashboard.organization.set-internal-contacts");
    const canSetHasPrivateDataCluster = this.hasPermission("internal-dashboard.organization.set-has-private-data-clusters");
    const canSetTechnicalAccountManager = this.hasPermission("internal-dashboard.organization.set-technical-account-manager");
    const canSetOrganizationAutomaticInvoiceVerification = this.hasPermission("internal-dashboard.organization.set-automatic-invoice-verification");
    const canSetOrganizationAllowDelinquentPayer = this.hasPermission("internal-dashboard.organization.set-allow-delinquent-payer");
    const canSetDisableCustomCPUSettings = this.hasPermission("internal-dashboard.organization.set-disable-custom-cpu");
    const canSetDisableCustomDiskSettings = this.hasPermission("internal-dashboard.organization.set-disable-custom-disk");

    return (
      <div>
        <ContentSegment>
          <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
          <SecondaryMenu>
            <MenuActionBack />
            <Menu.Item header>Organization Details</Menu.Item>
            <LoaderBox>
              <Loader size="mini" active={this.props.loading} inline />
            </LoaderBox>
          </SecondaryMenu>
          {organization && organizationInfo && (
            <Section>
              <SectionContent>
                <OrganizationDetailsView
                  {...this}
                  {...this.props}
                  {...this.state}
                  organization={organization}
                  organizationInfo={organizationInfo}
                  entityInfo={this.state.entityInfo || {}}
                  onRefreshOrganization={this.refreshOrganizationInfo}
                  canEditCustomInvoiceThreshold={canEditCustomInvoiceThreshold}
                  onUpdateCustomInvoiceThreshold={this.onUpdateCustomInvoiceThreshold}
                  onUpdateTechnicalAccountManager={this.onUpdateTechnicalAccountManager}
                  commentList={this.state.commentList}
                  canViewComments={canViewComments}
                  canCreateComment={canCreateComment}
                  onCommentsPageChange={this.onCommentsPageChange}
                  commentsFetchError={this.state.commentsFetchError}
                  createComment={this.createComment}
                  commentsLoading={this.state.commentsLoading}
                  commentCreationInProcess={this.state.commentCreationInProcess}
                  canSetInternalContacts={canSetInternalContacts}
                  canSetHasPrivateDataCluster={canSetHasPrivateDataCluster}
                  canSetTechnicalAccountManager={canSetTechnicalAccountManager}
                  canSetOrganizationAutomaticInvoiceVerification={canSetOrganizationAutomaticInvoiceVerification}
                  canSetOrganizationAllowDelinquentPayer={canSetOrganizationAllowDelinquentPayer}
                  canSetDisableCustomCPUSettings={canSetDisableCustomCPUSettings}
                  canSetDisableCustomDiskSettings={canSetDisableCustomDiskSettings}
                />
              </SectionContent>
            </Section>
          )}
          {organization && <TabLayoutWithRouter id="organizationDetailsTab" allowOverflow tabConfig={this.getTabConfig()} />}
        </ContentSegment>
      </div>
    );
  }
}

export default withRefresh()(OrganizationDetails);
