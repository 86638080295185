//
// DISCLAIMER
//
// Copyright 2024 ArangoDB GmbH, Cologne, Germany
//

import React from "react";
import { Button, ButtonGroup, Form, Modal } from "semantic-ui-react";
import { NotificationSeverity } from "../../api/lib";
import { FormActionButtonCancel, FormActionButtonSave } from "../../ui/lib";
import { dateForDateTimeInputValue } from "../../util/dateAndTimeUtils/DateTime"; // Add this import
import { DateTimePicker } from "./DateTimePicker";

export type NotificationData = {
  notificationText: string;
  severity: NotificationSeverity;
  notificationId: string;
  expiresAt?: Date;
};
export const CreateNotificationModal = ({
  onClose,
  onCreateNotification,
}: {
  onClose: () => void;
  onCreateNotification: (values: NotificationData) => Promise<void>;
}) => {
  const [notificationId, setNotificationId] = React.useState("");
  const [notificationText, setNotificationText] = React.useState("");
  const [severity, onSeverityChange] = React.useState(NotificationSeverity.NOTIFICATION_SEVERITY_INFO);
  const [expiresAt, setExpiresAt] = React.useState<Date | undefined>(undefined);
  const [processing, setProcessing] = React.useState(false);
  const onSubmit = async () => {
    setProcessing(true);
    // Do something with notification
    await onCreateNotification({
      notificationText,
      severity,
      notificationId,
      expiresAt,
    });
    setProcessing(false);
    onClose();
  };
  return (
    <Modal open={true} size="large">
      <Modal.Header>Create new notification</Modal.Header>
      <Modal.Content scrolling>
        <Form onSubmit={onSubmit}>
          <Form.Field>
            <Form.Input
              name="notificationId"
              value={notificationId}
              label="Notification ID"
              placeholder="Notification ID"
              required
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                setNotificationId(target.value);
              }}
            />
          </Form.Field>
          <Form.Field>
            <Form.TextArea
              name="notification"
              value={notificationText}
              label="Notification"
              placeholder="Notification"
              required
              onChange={({ target }: React.ChangeEvent<HTMLTextAreaElement>) => {
                setNotificationText(target.value);
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Date and time</label>
            <DateTimePicker
              dateTime={expiresAt ? dateForDateTimeInputValue(expiresAt) : ""}
              onClear={() => {
                setExpiresAt(undefined);
              }}
              onChange={(newDateTime) => {
                if (newDateTime) {
                  setExpiresAt(new Date(newDateTime));
                } else {
                  setExpiresAt(undefined);
                }
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Severity</label>
            <ButtonGroup>
              <Button
                type="button"
                content="Info"
                primary={severity === NotificationSeverity.NOTIFICATION_SEVERITY_INFO}
                onClick={() => onSeverityChange(NotificationSeverity.NOTIFICATION_SEVERITY_INFO)}
              />
              <Button
                type="button"
                content="Warning"
                primary={severity === NotificationSeverity.NOTIFICATION_SEVERITY_WARNING}
                onClick={() => onSeverityChange(NotificationSeverity.NOTIFICATION_SEVERITY_WARNING)}
              />
              <Button
                type="button"
                content="Critical"
                primary={severity === NotificationSeverity.NOTIFICATION_SEVERITY_CRITICAL}
                onClick={() => onSeverityChange(NotificationSeverity.NOTIFICATION_SEVERITY_CRITICAL)}
              />
            </ButtonGroup>
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel icon="cancel" onClick={onClose} />
        <FormActionButtonSave primary type="submit" onClick={onSubmit} disabled={processing} />
      </Modal.Actions>
    </Modal>
  );
};
