//
// DISCLAIMER
//
// Copyright 2024 ArangoDB GmbH, Cologne, Germany
//

import { useState } from "react";
import apiClients from "../../../api/apiclients";
import { Deployment } from "../../../api/lib";
import { NotificationsListView } from "../../notifications/NotificationsListView";
import { ErrorMessage } from "../../../ui/_errorMessage";

export const DeploymentNotificationsList = ({ deployment, reloadDeploymentInfo }: { deployment: Deployment; reloadDeploymentInfo: () => void }) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const clearError = () => setError(undefined);
  return (
    <>
      <ErrorMessage active={!!error} onDismiss={clearError} message={error} />

      <NotificationsListView
        onDeleteNotification={async (notificationId) => {
          try {
            await apiClients.idashboardClient.RemoveDeploymentNotification({
              deployment_id: deployment.id,
              notification_id: notificationId,
            });
            reloadDeploymentInfo();
          } catch (error) {
            setError(error.message);
          }
        }}
        onCreateNotification={async (values) => {
          try {
            await apiClients.idashboardClient.UpdateDeploymentNotification({
              deployment_id: deployment.id,
              notification_text: values.notificationText,
              severity: values.severity,
              notification_id: values.notificationId,
            });
            reloadDeploymentInfo();
          } catch (error) {
            setError(error.message);
          }
        }}
        notifications={deployment.notifications}
      />
    </>
  );
};
