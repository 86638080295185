//
// DISCLAIMER
//
// Copyright 2024 ArangoDB GmbH, Cologne, Germany
//

import { css, Global } from "@emotion/core";
import moment from "moment";
import { useState } from "react";
import { FlexBox } from "../../ui/_flex";

export const DateTimePicker = ({ onChange, dateTime }: { onChange: (dateTime: string | null) => void; onClear: () => void; dateTime?: string }) => {
  const [currentDateTime, setDateTime] = useState(dateTime || "");

  const onDateTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setDateTime(newValue);
    onChange(newValue || null);
  };
  const localDate = new Date(currentDateTime);

  return (
    <FlexBox gap="12px" direction="column">
      <FlexBox align="center" gap="12px">
        <Global
          styles={css`
            .SingleDatePicker_picker {
              z-index: 9999;
            }
          `}
        />
        <FlexBox flex="0 0 0">
          <input aria-label="Time" type="datetime-local" value={currentDateTime} onChange={onDateTimeChange} />
        </FlexBox>
      </FlexBox>
      <FlexBox flex="1 0 0">
        {currentDateTime && (
          <FlexBox direction="column" gap="12px">
            <span>Local: {moment(localDate).format("MMMM Do YYYY, h:mm:ss A z")}</span>
            <span>UTC: {moment.utc(localDate).format("MMMM Do YYYY, h:mm:ss A z")}</span>
          </FlexBox>
        )}
      </FlexBox>
    </FlexBox>
  );
};
