//
// DISCLAIMER
//
// Copyright 2024 ArangoDB GmbH, Cologne, Germany

//
import React from "react";
import { Button, Table } from "semantic-ui-react";
import { Notification } from "../../api/lib";
import { ContentSegment } from "../../ui/_style";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { CreateNotificationModal, NotificationData } from "./CreateNotificationModal";
import { EditNotificationModal } from "./EditNotificationModal";

export const NotificationsListView = ({
  notifications,
  onCreateNotification,
  onDeleteNotification,
}: {
  onCreateNotification: (values: NotificationData) => Promise<void>;
  onDeleteNotification: (notificationId: string) => Promise<void>;
  notifications:
    | {
        [key: string]: Notification;
      }
    | undefined;
}) => {
  const [isOpenCreateNotification, setIsOpenCreateNotification] = React.useState(false);
  const [isOpenEditNotification, setIsOpenEditNotification] = React.useState(false);
  const onOpenCreateNotification = () => {
    setIsOpenCreateNotification(true);
  };
  const [activeNotificationData, setActiveNotificationData] = React.useState<{
    notification: Notification;
    id: string;
  } | null>(null);
  return (
    <ContentSegment>
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Notification ID</Table.HeaderCell>
            <Table.HeaderCell>Notification Text</Table.HeaderCell>
            <Table.HeaderCell>Created At</Table.HeaderCell>
            <Table.HeaderCell>Updated At</Table.HeaderCell>
            <Table.HeaderCell>Severity</Table.HeaderCell>
            <Table.HeaderCell>Expires At</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              <Button icon="plus" size="tiny" basic onClick={onOpenCreateNotification} />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {notifications ? (
            Object.entries(notifications).map(([id, notification]) => (
              <Table.Row key={id}>
                <Table.Cell>{id}</Table.Cell>
                <Table.Cell>{notification.notification}</Table.Cell>
                <Table.Cell>
                  <DateTimePopupWithUTCAndLocalTime dateTime={notification.created_at} label="Created at" />
                </Table.Cell>
                <Table.Cell>
                  <DateTimePopupWithUTCAndLocalTime dateTime={notification.updated_at} label="Updated at" />
                </Table.Cell>
                <Table.Cell>{notification.severity}</Table.Cell>
                <Table.Cell>
                  <DateTimePopupWithUTCAndLocalTime dateTime={notification.expires_at} label="Expires at" />
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <Button
                    icon="edit"
                    size="tiny"
                    basic
                    onClick={() => {
                      setIsOpenEditNotification(true);
                      setActiveNotificationData({ notification, id });
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan={5}>No notifications</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      {isOpenEditNotification && activeNotificationData && (
        <EditNotificationModal
          notificationData={activeNotificationData}
          onDeleteNotification={onDeleteNotification}
          onCreateNotification={onCreateNotification}
          onClose={() => {
            setIsOpenEditNotification(false);
            setActiveNotificationData(null);
          }}
        />
      )}
      {isOpenCreateNotification && <CreateNotificationModal onCreateNotification={onCreateNotification} onClose={() => setIsOpenCreateNotification(false)} />}
    </ContentSegment>
  );
};
