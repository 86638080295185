//
// DISCLAIMER
//
// Copyright 2024 ArangoDB GmbH, Cologne, Germany

import { Organization } from "../../api/lib";
import { NotificationsListView } from "../notifications/NotificationsListView";
import apiClients from "../../api/apiclients";
import { useState } from "react";
import { ErrorMessage } from "../../ui/_errorMessage";

export const OrganizationNotificationsList = ({
  organization,
  refreshOrganizationInfo,
}: {
  organization: Organization;
  refreshOrganizationInfo: () => void;
}) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const clearError = () => setError(undefined);

  return (
    <>
      <ErrorMessage active={!!error} onDismiss={clearError} message={error} />
      <NotificationsListView
        onDeleteNotification={async (notificationId) => {
          try {
            await apiClients.idashboardClient.RemoveOrganizationNotification({
              organization_id: organization.id,
              notification_id: notificationId,
            });
            refreshOrganizationInfo();
          } catch (error) {
            setError(error.message);
            console.error(error);
          }
        }}
        onCreateNotification={async (values) => {
          try {
            await apiClients.idashboardClient.UpdateOrganizationNotification({
              organization_id: organization.id,
              notification_text: values.notificationText,
              severity: values.severity,
              notification_id: values.notificationId,
              expires_at: values.expiresAt,
            });
            refreshOrganizationInfo();
          } catch (error) {
            setError(error.message);
            console.error(error);
          }
        }}
        notifications={organization.notifications}
      />
    </>
  );
};
